<template>
    <div>
        <div class="h-100 bg-light">
            <div class="d-flex h-100 justify-content-center align-items-center">
                <b-col md="8" class="mx-auto app-login-box d-block">
                    <div class="d-flex w-auto justify-content-center">
                        <img src="@/assets/images/logo-login.png" height="100px" class="d-block img-fluid logo-img" />

                    </div>
                    <div class="mx-auto mb-3"/>

                        <form action="" method="POST" @submit.prevent="login" v-on:keyup.enter="login">
                            <div class="modal-dialog w-100 mx-auto">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <div class="h5 modal-title text-center">
                                            <h4 class="mt-2">
                                                <span>Please sign in to your account.</span>
                                            </h4>
                                        </div>
                                        <b-form-group id="username"
                                                      label-for="username">
                                            <b-form-input id="username"
                                                          type="text"
                                                          required
                                                          placeholder="Enter username..."
                                                          v-model="username"
                                            >
                                            </b-form-input>
                                        </b-form-group>
                                        <b-form-group id="password"
                                                      label-for="password">
                                            <b-form-input id="password"
                                                          type="password"
                                                          required
                                                          placeholder="Enter password..."
                                                          v-model="password"
                                            >
                                            </b-form-input>
                                        </b-form-group>

                                        <div class="form-group">
                                            <div v-if="error" class="alert alert-danger" role="alert">{{error}}</div>
                                        </div>
                                    </div>
                                    <div class="modal-footer clearfix">
                                        <div class="float-left">
                                            <a href="/#/password-recovery" class="btn-lg btn btn-link">Recover
                                                Password</a>
                                        </div>
                                        <div class="float-right">
                                            <v-btn color="primary" class="text-unset" sm @click="login">Login to Dashboard</v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    <div class="text-center opacity-8 mt-3">
                        Copyright &copy; Hotelium 2024
                    </div>
                </b-col>
            </div>
        </div>
    </div>
</template>


<script>
    import gql from 'graphql-tag'
    import { onLogin } from '../../vue-apollo.js'
    export default {
        name: "Login",
        data() {
            return {
                username: '',
                password: '',
                loading: false,
                message: '',
                token: '',
                terms: false,
                error: null
            }
        },
        created() {
            if (this.$route.params.uid) {
                this.axios.get("/admin-api/redirlogin?access_token="+localStorage.getItem('apollo-token')+"&id="+this.$route.params.uid).then((data)=> {
                    sessionStorage.setItem('apollo-token', data.data.token)
                    localStorage.setItem('mimic-token', data.data.token)
                })
                sessionStorage.setItem('afterLogin', "1")
                this.$router.push('/')
            }
            if (localStorage.getItem('apollo-token') !== null) {
                sessionStorage.setItem('afterLogin', "1")
                this.$router.push('/')
            }

        },
        methods: {
            login() {
                this.$apollo.mutate({
                    mutation: gql`mutation ($data: AuthenticateInput!) {
                      authenticate(input: $data) {
                        error,
                        token,
                        user{id, username, email, firstName}
                      }
                    }`,
                    variables: {
                        data: {
                            username: this.username,
                            password: this.password
                        }
                    }
                }).then((data) => {
                    if (data.data.authenticate.error) {
                        this.error = data.data.authenticate.error
                        this.username = ''
                        this.password = ''
                    }
                    else {
                        onLogin(this.$apollo.provider.defaultClient, data.data.authenticate.token)
                        this.$router.push('/')
                    }
                }).catch((error) => {
                    this.error = error
                    this.username = ''
                    this.password = ''
                })
            }
        }
    }
</script>

<style scoped>
    .centered {
        margin: auto;
    }

    .logo-img {
        max-width: 175px;
        max-height: 100px;
    }

</style>